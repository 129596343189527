import inview from "./utils/inview";
import eventBus from "./utils/eventBus";

export default function TextAnim(el) {
  const textEl = el.querySelector("[data-text]");
  const text = textEl.innerText;
  // const repeat = el.dataset?.repeat == "true";
  const delay = +(el.dataset?.delay ?? 0);

  inview.set(el, {
    enter() {
      setTimeout(() => {
        el.style.transition = "opacity 0.5s ease-out";
        el.style.opacity = 1;
        animate();
      }, delay);
    },
    leave() {
      console.log("text leave");
      el.style.opacity = 0;
    },
  });

  function animate() {
    let delay = 0;
    let colorDelay = delay / 1000;

    try {
      textEl.innerHTML = text
        .split(" ")
        .map((word, i) => {
          const newWord = word
            .split("")
            .map((letter) => {
              delay += 0.02;
              return `<span class="ta-letter" style="animation-delay: ${delay}s">${letter}</span>`;
            })
            .join("");

          let color = [
            "award",
            "winning,",
            "creative,",
            "digital",
            "ideas",
            "iPOP",
            "Creativity",
            "Since",
            "2012,",
            "300",
            "brands.",
            "We’re",
            "much",
            "more",
            "Contact",
            "agency",
            "brands",
            "websites",
          ].includes(word.trim());

          if (color) {
            colorDelay += 0.1;
          }

          return `<span class='ta-word ${
            color ? " ta-color " : ""
          }' style='animation-delay: ${colorDelay}s'>${newWord}</span>`;
        })
        .join("");

      eventBus.emit("scrolltrigger:refresh");
    } catch (error) {}
  }

  return () => {};
}

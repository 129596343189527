import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import eventBus from "./utils/eventBus";
gsap.registerPlugin(ScrollTrigger);

export default function Animations(el) {
  eventBus.on("scrolltrigger:refresh", () => ScrollTrigger.refresh());

  const tl = gsap.timeline();

  const mySplitText = new SplitText(".text-repeater h2", {
      type: "lines,chars",
    }),
    chars = mySplitText.chars, //an array of all the divs that wrap each character
    lines = mySplitText.lines; //an array of all the divs that wrap each line

  lines.forEach((line) => {
    const divs = line.querySelectorAll("div");
    tl.from(
      divs,
      {
        delay: 2,
        duration: 0.3,
        opacity: 0,
        y: -50,
        ease: "back",
        stagger: 0.05,
      },
      "-=0.5"
    );
  });
}

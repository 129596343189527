import "core-js/stable";
import "regenerator-runtime/runtime";
import Slider from "./js/Slider";
import "./app.scss";
import Animations from "./js/fadeText";
import Menu from "./js/Menu";
import TextAnim from "./js/TextAnim";
import MenuClose from "./js/MenuClose";
import Masonry from "masonry-layout";
import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import lazySizes from "lazysizes";
import Video from "./js/Video";
import Accordion from "./js/Accordion";
import Headroom from "headroom.js";

// Only used in dev
if (module.hot) {
  module.hot.accept();
}

let actions = {};
let actionChain = new Map();
function registerAction(action) {
  actions[action.name] = {
    mount: action,
  };
}
function action(attr, func) {
  if (attr.indexOf(".") !== -1 || attr.indexOf("#") !== -1) {
    document.querySelectorAll(attr).forEach((el) => func(el));
  } else {
    document
      .querySelectorAll(`[data-action="${attr}"]`)
      .forEach((el) => func(el));
  }
}
gsap.registerPlugin(ScrollTrigger);
registerAction(Animations);
registerAction(Slider);
registerAction(TextAnim);
runActions();

function runActions() {
  action("Video", Video);
  action("Slider", Slider);
  action("TextAnim", TextAnim);
  Menu(document.querySelectorAll(".mob-nav-btn"));
  MenuClose(document.querySelectorAll(".mob-close"));
  const headroomElement = document.querySelector("#main-header");
  const headroom = new Headroom(headroomElement, {
    offset: 100,
  });
  headroom.init();
  Accordion();
}
let msn;

function initMason() {
  if (!document.querySelector(".masonry")) return;
  msn?.destroy();
  msn = new Masonry(".masonry", {
    itemSelector: ".card-load",
  });
}
initMason();
function is_iphone() {
  return /iPhone/.test(navigator.userAgent);
}

function is_low_power() {
  return (
    navigator.getBattery &&
    navigator.getBattery().then(function (battery) {
      return battery.level < 0.2;
    })
  );
}

export default function Accordion() {
    const accordion = document.querySelector('#accordion-open');

    if (accordion) {
        accordion.addEventListener('click', function (e) {
            const header = e.target.closest('button.accordion-header');

            if (header) {
                const contentDiv = header.parentElement.nextElementSibling;
                const plusIcon = header.querySelector('.plus-icon');
                const minusIcon = header.querySelector('.minus-icon');

                if (contentDiv) {
                    contentDiv.classList.toggle('hidden');
                    plusIcon.classList.toggle('hidden');
                    minusIcon.classList.toggle('hidden');
                }
            }
        });
    }
}
